/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import Button from '@rotaryintl/harris-button';
import Select from '@rotaryintl/harris-form-basic/dist/SelectField/Select';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { isEmpty } from 'lodash';

import CDSFinancialCard from './CDSFinancialCard';
import { cdsRepresentatives } from './CDSFinancialMockData';

import { useFetchAllDistricts } from '@repositories/districts';

import { useTranslation } from '@external/react-i18next';

import { CDSFormInitialValues } from '@typings/cds-financial';

export interface CDSFinancialFormProps {
  setRepsAvailable: Dispatch<SetStateAction<boolean>>;
}

const CDSFinancialForm: React.FC<CDSFinancialFormProps> = ({
  setRepsAvailable,
}) => {
  const { t } = useTranslation();
  const [showCards, setShowCards] = useState(false);

  const [fetchAllDistricts, { data }] = useFetchAllDistricts();
  const districtDropdownRef = useRef<any>(null);
  const initialValues: CDSFormInitialValues = {
    district: '',
  };

  useEffect(() => {
    fetchAllDistricts({
      variables: {
        status: 'active',
      },
    });
  }, []);

  const handleFormChange = (e: FormEvent) => {
    e.preventDefault();
    setShowCards(false);
    setRepsAvailable(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        values,
        { setSubmitting }: FormikHelpers<CDSFormInitialValues>
      ) => {
        setShowCards(false);
        setRepsAvailable(false);
        setSubmitting(false);
        if (!isEmpty(values?.district)) {
          setShowCards(true);
        }
      }}
      onReset={() => {
        districtDropdownRef.current?.clearValue();
        setShowCards(false);
        setRepsAvailable(false);
      }}
    >
      {({
        values,
        handleSubmit,
        resetForm,
      }: FormikProps<CDSFormInitialValues>) => {
        return (
          <Form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Select
              name="district"
              label={t(
                'cds-financial.select-district-placeholder',
                'Select your district'
              )}
              placeholder={t(
                'cds-financial.select-district-placeholder',
                'Select your district'
              )}
              options={
                data?.districts
                  ?.map(district => ({
                    label: String(district.riDistrictId),
                    value: String(district.riDistrictId),
                  }))
                  .sort(
                    ({ value: firstDistrict }, { value: secondDistrict }) =>
                      Number(firstDistrict) - Number(secondDistrict)
                  ) || []
              }
              clearable
              searchable
              dropdownRef={districtDropdownRef}
              menuItemHeight="89px"
              onChange={() => {
                setRepsAvailable(false);
              }}
            />
            <section className="flex-1 mt-4 button-section">
              <Button
                variant="primary"
                disabled={false}
                clickHandler={handleSubmit}
                className="mr-6"
              >
                {t('cds-financial.select', 'Select')}
              </Button>
              {!isEmpty(values?.district) && (
                <Button size="md" variant="text" clickHandler={resetForm}>
                  {t('cds-financial.reset', 'Reset')}
                </Button>
              )}
            </section>
            {showCards &&
              cdsRepresentatives?.map(cdsRepresentative => {
                if (values.district === cdsRepresentative.district) {
                  if (!isEmpty(cdsRepresentative.represetatives)) {
                    setRepsAvailable(true);
                  }
                  return (
                    <CDSFinancialCard
                      {...cdsRepresentative}
                      key={cdsRepresentative.id}
                    />
                  );
                }
                return null;
              })}
          </Form>
        );
      }}
    </Formik>
  );
};
export default CDSFinancialForm;
