import React, { useState } from 'react';
import { OneColumn } from '@components/Layouts/OneColumn';

import { useTranslation } from '@external/react-i18next';

import CDSFinancialForm from '@presenters/web/components/CDSFinancial/CDSFinancialForm';

import '../presenters/web/components/CDSFinancial/styles.css';

const CDSFinancialPage: React.FC = () => {
  const { t } = useTranslation();
  const [repsAvailable, setRepsAvailable] = useState(false);
  return (
    <OneColumn
      className={repsAvailable ? 'cds-cards-available' : 'cds-cards-empty'}
    >
      <h1 className="cds-title cds-title-color font-semibold mb-6 mt-6">
        {t('cds-financial.title', 'Find Your Representatives')}
      </h1>
      <section>
        <p className="cds-title-color cds-description text-base font-normal not-italic leading-6">
          {t(
            'cds-financial.description',
            'Find a support representative for your district'
          )}
        </p>
      </section>
      <CDSFinancialForm setRepsAvailable={setRepsAvailable} />
    </OneColumn>
  );
};
export default CDSFinancialPage;
