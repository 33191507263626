import { CDSRepresentative } from '@typings/cds-financial';

export const cdsRepresentatives: CDSRepresentative[] = [
  {
    id: '100',
    district: '1410',
    cardHeading: 'Finance',
    cardDescription:
      'Contact lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit ut nisi sed sodales. Suspendisse ac condimentum enim. Sed suscipit fringilla sapien nec.',
    represetatives: [
      {
        id: '1',
        position: 'Accounts Receivable',
        contact: 'Nelson Calix, Collection Coordinator',
        phone: '+1 847 866 3353',
        fax: '+1 847 866 3354',
        email: 'nelson.calix@rotary.org',
      },
    ],
  },
  {
    id: '101',
    district: '1410',
    cardHeading: 'Member and Participant Experience',
    cardDescription:
      'Contact lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit ut nisi sed sodales. Suspendisse ac condimentum enim. Sed suscipit fringilla sapien nec.',
    represetatives: [
      {
        id: '1',
        position: 'Club and District Support',
        contact: 'Nick Taylor, Supervisor',
        phone: '+1 847 866 3429',
        fax: '',
        email: 'nick.taylor@rotary.org',
      },
      {
        id: '2',
        position: 'Membership Development',
        contact:
          'Angela Lopez, Regional Membership officer this title goes long',
        phone: '+1 847 425 5688',
        fax: '',
        email: 'angela.lopez@rotary.org',
      },
    ],
  },
  {
    id: '102',
    district: '1420',
    cardHeading: 'Finance',
    cardDescription:
      'Contact lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit ut nisi sed sodales. Suspendisse ac condimentum enim. Sed suscipit fringilla sapien nec.',
    represetatives: [
      {
        id: '1',
        position: 'Accounts Receivable',
        contact: 'Nelson Calix, Collection Coordinator',
        phone: '+1 847 866 3353',
        fax: '+1 847 866 3354',
        email: 'nelson.calix@rotary.org',
      },
    ],
  },
  {
    id: '103',
    district: '1420',
    cardHeading: 'Member and Participant Experience',
    cardDescription:
      'Contact lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit ut nisi sed sodales. Suspendisse ac condimentum enim. Sed suscipit fringilla sapien nec.',
    represetatives: [
      {
        id: '1',
        position: 'Club and District Support',
        contact: 'Nick Taylor, Supervisor',
        phone: '+1 847 866 3429',
        fax: '',
        email: 'nick.taylor@rotary.org',
      },
      {
        id: '2',
        position: 'Membership Development',
        contact:
          'Angela Lopez, Regional Membership officer this title goes long',
        phone: '+1 847 425 5688',
        fax: '',
        email: 'angela.lopez@rotary.org',
      },
    ],
  },
  {
    id: '104',
    district: '1420',
    cardHeading: 'Rotary Grants',
    cardDescription:
      'Contact lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit ut nisi sed sodales. Suspendisse ac condimentum enim. Sed suscipit fringilla sapien nec.',
    represetatives: [
      {
        id: '1',
        position: 'District Grants',
        contact: 'Cecelia Waler, Regional Grants Officer',
        phone: '+1 847 866 5266',
        fax: '',
        email: 'cecelia.walter@rotary.org',
      },
      {
        id: '2',
        position: 'Global Grants',
        contact: 'Cecelia Waler, Global Grants Officer',
        phone: '+1 847 866 3195',
        fax: '',
        email: 'cecelia.walter@rotary.org',
      },
    ],
  },
  {
    id: '105',
    district: '1420',
    cardHeading: 'The Rotary Foundation',
    cardDescription:
      'Contact lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit ut nisi sed sodales. Suspendisse ac condimentum enim. Sed suscipit fringilla sapien nec.',
    represetatives: [
      {
        id: '1',
        position: 'Annual Giving',
        contact: 'Geoff Cochran, Annual Giving Officer',
        phone: '+1 847 866 3195',
        fax: '',
        email: 'geoff.cochran@rotary.org',
      },
      {
        id: '2',
        position: 'Major Gifts',
        contact: 'Laura Mello, Major Gift Officer',
        phone: '+1 847 424 5316',
        fax: '',
        email: 'laura.mello@rotary.org',
      },
      {
        id: '3',
        position: 'Planned Giving',
        contact: 'Erin Groble, Planned Giving Officer',
        phone: '+1 847 424 5376',
        fax: '',
        email: 'erin.groble@rotary.org',
      },
    ],
  },
];
