import React from 'react';

import CardContainer from '@rotaryintl/harris-card/dist/card-container/card-container.component';
import IconFont from '@rotaryintl/harris-icon-font';
import { Column, ColumnLayout } from '@rotaryintl/myrotary-column-layout';

import { CDSRepresentative } from '@typings/cds-financial';

const CDSFinancialCard: React.FC<CDSRepresentative> = ({
  cardHeading,
  cardDescription,
  represetatives,
}) => {
  return (
    <section className="mt-8 cds-card-section">
      <CardContainer className="rwc-card grid grid-col-1 cds-card-container">
        <>
          <section className="cds-card-heading">
            <h4 className="cds-title-color cds-card-heading-size cds-card-heading-decor">
              {cardHeading}
            </h4>
            <p className="cds-title-color text-wrap normal-case cds-card-description-size cds-card-description-decor">
              {cardDescription}
            </p>
          </section>
          <ColumnLayout className="m-0 p-0 cds-column-layout">
            {represetatives?.map(representative => (
              <Column span="6" key={representative.id}>
                <div>
                  <h5 className="mb-1 cds-title-color normal-case cds-card-position-size cds-card-position-decor">
                    {representative.position}
                  </h5>
                  <div className="cds-title-color normal-case cds-card-contact-size cds-card-contact-decor mb-4">
                    {representative.contact}
                  </div>
                  {representative.phone && (
                    <div className="rwc-contact-card__phone-container cds-card-position-size flex mb-1">
                      <div className="rwc-contact-card__icon rwc-icon mr-1">
                        <IconFont type="fas-phone" aria-hidden="true" />
                      </div>
                      <div className="cds-title-color cds-card-phone-size cds-card-phone-decor">
                        {representative.phone}
                      </div>
                    </div>
                  )}
                  {representative.fax && (
                    <div className="rwc-contact-card__email-container cds-card-position-size flex mb-1">
                      <div className="rwc-contact-card__icon rwc-icon mr-1">
                        <IconFont type="fas-fax" aria-hidden="true" />
                      </div>
                      <div className="cds-title-color cds-card-phone-size cds-card-phone-decor">
                        {representative.fax}
                      </div>
                    </div>
                  )}
                  {representative.email && (
                    <div className="rwc-contact-card__email-container cds-card-position-size flex mb-1">
                      <div className="rwc-contact-card__icon rwc-icon mr-1">
                        <IconFont type="fas-envelope" aria-hidden="true" />
                      </div>
                      <a
                        href={`mailto:${representative.email}`}
                        className="cds-card-email-decor cds-email-color"
                      >
                        {representative.email}
                      </a>
                    </div>
                  )}
                </div>
              </Column>
            ))}
          </ColumnLayout>
        </>
      </CardContainer>
    </section>
  );
};
export default CDSFinancialCard;
